import React, { useContext } from "react";
import { Form, Field } from "react-final-form";
import { navigate } from "gatsby";

import { BookingContext } from "../../contexts/BookingContext";

import BookingRoomHeader from "../../components/booking/bookingRoomHeader";
import BookingDateTime from "../../components/booking/bookingDateTime";
import BookingRoomSummary from "../../components/booking/bookingRoomSummary";
import BookingRoomCostTotal from "../../components/booking/bookingRoomCostTotal";

import {
  bookingInfoWrapper,
  contactFormPC,
  contactFormPart,
  contactFormC,
  bookingFacilitiesDetails,
  bookingEventDetailsWrapper,
  bookingExtrasWrapper,
} from "../../css/booking.module.css";

const BookingEventDetails = () => {
  const [bookingValues, setBookingValues] = useContext(BookingContext);

  const onSubmit = async (values) => {
    setBookingValues({ ...bookingValues, bookingContact: values });
    navigate(`/rooms/${bookingValues.bookingRoom.slug}/book/confirm`);
  };

  return (
    <div className="content-wrapper-column">
      <BookingRoomHeader />
      <BookingDateTime />
      <div className={bookingExtrasWrapper}>
        <div className={bookingEventDetailsWrapper}>
          <h2>Your contact details</h2>
          <div className={bookingFacilitiesDetails}>
            <p>
              We’ll only use your details to get in touch about your booking,
              and for any arrangements on the day of your event
            </p>
            <Form
              onSubmit={onSubmit}
              //   validate={validate}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit} className={contactFormC}>
                  <div className={contactFormPart}>
                    <div>
                      <label htmlFor="fullName">Full name</label>
                      <Field
                        name="fullName"
                        component="input"
                        type="text"
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="email">Email address</label>
                      <Field
                        name="email"
                        component="input"
                        type="email"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="phoneNumber">Phone number</label>
                      <Field
                        name="phoneNumber"
                        component="input"
                        type="tel"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="addressName">Address</label>
                      <Field
                        name="addressName"
                        component="input"
                        type="text"
                        placeholder="House number or name"
                        required
                      />
                    </div>
                    <div>
                      <Field
                        name="addressStreet"
                        component="input"
                        type="text"
                        placeholder="Street"
                        required
                      />
                    </div>
                    <div>
                      <Field
                        name="addressTown"
                        component="input"
                        type="text"
                        placeholder="Town/city"
                        required
                      />
                    </div>
                    <div>
                      <Field
                        name="addressCounty"
                        component="input"
                        type="text"
                        placeholder="County"
                      />
                    </div>
                  </div>

                  <div className={contactFormPC}>
                    <label htmlFor="postCode">Postcode</label>
                    <Field
                      name="postCode"
                      component="input"
                      type="text"
                      required
                    />
                  </div>

                  <p>You can review your booking request before it’s final</p>
                  <button
                    type="submit"
                    disabled={submitting || pristine}
                    className="btn-green-light"
                  >
                    Save and continue
                  </button>
                </form>
              )}
            />
          </div>
        </div>

        <div className={bookingInfoWrapper}>
          <BookingRoomSummary />
          <BookingRoomCostTotal />
        </div>
      </div>
    </div>
  );
};

export default BookingEventDetails;
