import React, { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../contexts/BookingContext";

import { bookingHoursCalc } from "../../utils/dateUtils";

import { bookingTotals, bookingRoomCost } from "../../css/booking.module.css";

const BookingRoomCostTotal = () => {
  const [bookingValues] = useContext(BookingContext);
  const [room, setRoom] = useState();
  const [bookingDate, setBookingDate] = useState(null);
  const [extras, setExtras] = useState();
  const [runningTotal, setRunningTotal] = useState();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingRoom && setRoom(bookingValues.bookingRoom);
      bookingValues.bookingDate && setBookingDate(bookingValues.bookingDate);
      bookingValues.bookingFacilities &&
        setExtras(bookingValues.bookingFacilities);
      // get the hours
      let bookedHours = 1;
      if (bookingDate) {
        bookedHours = bookingHoursCalc(bookingDate.start, bookingDate.end);
      }
      // get the costs
      let extrasCost = 0;
      if (extras) {
        extrasCost = extras.reduce(
          (costSoFar, extra) => costSoFar + extra.cost,
          0
        );
      }
      // get the booking type selected
      let eventType = "";
      if (bookingValues.bookingDetailsEvent) {
        eventType = bookingValues.bookingDetailsEvent.bookingType;
      }

      let eventTypeCost = 0;
      if (room) {
        const { cost } = room.prices.find(
          (item) => item.bookingType.name === eventType
        );
        eventTypeCost = cost;
      }

      const basicRoomTotal = eventTypeCost * bookedHours;
      const eventTotal = basicRoomTotal + extrasCost;

      setRunningTotal({
        bookedHours,
        eventType,
        eventTypeCost,
        basicRoomTotal,
        extrasCost,
        eventTotal,
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [
    bookingValues.bookingRoom,
    bookingValues.bookingDate,
    bookingDate,
    room,
    extras,
    bookingValues.bookingDetailsEvent,
    bookingValues.bookingFacilities,
  ]);

  return (
    <div className={bookingRoomCost}>
      <p>Price for your event</p>
      {runningTotal && (
        <div className={bookingTotals}>
          <ul>
            <li>
              Room cost: £{runningTotal.eventTypeCost} per hour (
              {runningTotal.eventType} use)
            </li>
            <li>Event length: {runningTotal.bookedHours} hours</li>
            <li>Basic room hire: £{runningTotal.basicRoomTotal}</li>
            <li>£{runningTotal.extrasCost} extras selected</li>
          </ul>
          <p>
            Total: <strong>£{runningTotal.eventTotal}</strong> inc. VAT
          </p>
        </div>
      )}
      <p>Hire agreement</p>
      <a href={room && room.hireAgreementLink}>
        Download Pannal Village Hall hire agreement
      </a>
      &nbsp;(PDF)
    </div>
  );
};

export default BookingRoomCostTotal;
